.note-announce{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    margin-bottom: 0px!important;
}
.note-announce p{
    margin-bottom: 0px !important;
}
.announce_wrap p{
    margin: 0;
}