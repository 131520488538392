.PriceWrap{
    width: 660px;
    padding-bottom: 40px;
}
.ship_p{
    margin: 0;
    padding-left: 10px;
}
.priceListBlock{
    /* height: 81px; */
    /* line-height: 81px; */
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #7C7F84;
    align-items: center;
}
.priceListBlock.boldHead{
    color: #58637A;
    font-family: 'Poppins-Medium';
    background: #E5EBF8;
    font-size: 18px;
    margin-bottom: 20px;
}
.priceListBlock div{
    width: 50%;
    /* padding-left: 50px; */
}
.blk_wrp{
    /* border: 1px solid #f4f7fc; */
    border-radius: 5px;
    padding-bottom: 35px;
}
.mre_btn_s{
    width: 85px;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0px;
    margin-top: 20px;
    cursor: pointer;
    color: #8b9fdf;
}
.blk_wrp .priceListBlock:nth-child(odd){
    background-color: #F4F7FC;
}
.prc_bu{
    /* width: 160px; */
    text-align: center;
    margin-bottom: 20px;
    background: #0a3ad68c;
    color: white;
    display: inline-block;
}
.conf_link{
    text-align: center;
    margin-bottom: 20px;
    display: inline-block; 
}
@media only screen and (max-width: 1680px) {
    .priceListBlock.boldHead{
        font-size: 16px;
    }
    .priceListBlock{
        font-size: 14px;
        /* height: 51px; */
        /* line-height: 51px; */
    }
}
@media only screen and (max-width: 1390px) {
    .PriceWrap{
        width: 642px;
    }
}