.rfq-ranking-modal{
  padding: 20px;
}

.rfq-ranking-modal .close-icon{
  padding: 10px;
}

.rfq-ranking-modal .bottom_statics_box{
  grid-template-columns: 1fr;
}
.rfq-ranking-table th,.rfq-ranking-table td{
  text-align: left!important;
}

.table-view-rfq-ranking th,.table-view-rfq-ranking td{

  text-align: left!important;
}

.rfq-ranking-back-btn{
  background: transparent;
  color: #808080;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 0;
  margin: 0;
}
.rfq-ranking-back-btn:hover{
  cursor: pointer;
}