.active-quote-container h3{
  font-size: 16px;
}
.active-quote-container .subtitle{
  color:#616365;
  font-size: 12px;
  margin-bottom: 25px;
  font-weight: 100;
}

.shipment-table-new{
  width: 100%;
  border: none;
  border-spacing: 0px;
}

.icon-container{
  align-items: center;
}


.shipment-table-new thead{
  background: #007381;
  color: #fff;
}

.shipment-table-new th,.shipment-table-new td{
  text-align: left;
}

.timeline h4,.timeline p{
  margin: 0;
  padding: 0;
}