.deduct-container{
  display: grid;
  grid-template-columns: 1fr;
}
.add-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-btn svg{
  border-radius: 50%;
  background: #007381;
  padding: 10px;
  cursor: pointer;
  color: #fff;
}
.dropdown__main{
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-bottom: 20px;
}
.dropdown-container{
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex:0.3 1; */
  display: grid;
  gap:10px;
}
.dropdown-container label{
  text-align: left;
  font-weight: 600;
}
.dropdown-container div,.dropdown-container select{
  text-align: left;
}
.day-container{
  display: grid;
}
.day-container label{
  font-weight: 600;
  text-align: left;
}
.day-container h4{
  text-align: left;
  margin-top:10px;
  margin-bottom: 0px;
}

.table-packing th,.table-packing td{
  text-align: left;
}
.table-packing .input-wrap{
  width: 100px;
  text-align: right;
}

.packing-btn-container{
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.packing-align-reverse{
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.packing-align-start{
  justify-content: flex-end;
  flex-direction: row;
}

.update-packing-btn,.refresh-packing-btn{
  padding:5px 15px;
  height: 40px;
  margin-top: 15px;
  outline: none;
  border: none;
  border-radius: 7px;
  color:#fff;
}
.update-packing-btn {
  background-color: #002776;
  width:125px;

}

.refresh-packing-btn{
  background-color: #007381;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-packing-btn:hover,.refresh-packing-btn:hover{
  cursor: pointer;
}

.table-packing-list{
  background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px #ddd;
    padding: 0;
}

.packing-details{
  display: grid;
  margin-left: 10px;
}
.packing-details p{
  margin: 0;
  margin-bottom: 10px;
}
.packing-details p span{
  font-weight: 600;
}