:root {
  --section-height:80px;
  --milestone-width:20px;
}

.timeline_wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--section-height);
  gap: 10px;
}
.timeline_date_wrap{
  position: relative;
}
.timeline_date{
  text-transform: uppercase;
  width: 90px;
}
.timeline_section_wrap::before{
  content: '';
  height: var(--section-height);
  width: 2px;
  background-color: #007381;
}
.timeline_section_wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.timeline_section_icon_wrap{
  background-color: #fff;
  position: absolute;
  left: -8px;
  top:-10%;
  display: flex;
  height: 25px; /* 5px greater than .timeline_section_icon */
  align-items: center;
}

.icon-1{
  top:-40%;
}
.icon-3{
  top:-60%;
}
.icon-5{
  top:-60%;
}
.icon-6{
  top:-20%;
}

.icon-22{
  top:-80%;
}
.icon-44{
  top:-50%;
  left: -10px;
}
.icon-66{
  top:-60%;
  left: -10px;
}
.icon-1010{
  top:-120%;
  left: -10px;
}

.timeline_section_icon{
  width: 20px;
  height: 20px;
}
.timeline_plate::after{
  content: '';
  position: absolute;
  top:50%;
  left: -10px;
  width: 20px;
  height: 1.5px;
  background-color: #007381;
}
.timeline_plate::before{
  content: '';
  position: absolute;
  top:45%;
  left: -10px;
  width: 20px;
  height: 10px;
  background-color: #fff;
}

.timeline_milestone{
  position: relative;
  width: 80px;
  height: 30px;
  /* background-color: aqua; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.timeline_milestone::before{
  content: '';
  width: 60px; /* 20px shorter than .timeline_milestone Width */
  height: 1.5px;
  background-color: #acacac;
  position: absolute;
  top:50%;
  right: var(--milestone-width);
  z-index: -1;
}
.timeline_milestone[aria-label="2"]::before{
  right: 16px;
  width: 64px;
}
.timeline_milestone span{
  position: absolute;
  font-size: 12px;
  top:0;
  left: 5px; /* ALIGN WITH .timeline_milestone::before */
}
.timeline_milestone img{
  width: var(--milestone-width);
  height: var(--milestone-width);
}
.timeline_description_wrap{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.timeline_description{
  margin: 0;
}
.timeline_pdf{
  font-size: 12px;
  display: flex;
  align-items: center;
  gap:5px;
  color:rgb(75, 75, 75)
}
.timeline_pdf_icon{
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.timeline_pdf_icon[alt='download']{
  margin-left: 40px;
}

.timeline_triangle{
  position: absolute;
  right: 0;
  width: 15px;
  height: 15px;
}