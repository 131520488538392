.doc-card *{
  margin: 0;
  padding: 0;
}
.cmn_head{
  margin: 15px 0px;
}
.doc-wrap{
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  gap:10px;
}

.doc-card{
  border: 1px solid lightgray;
  padding: 10px;
  width: 250px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-radius: 7px;
}
.doc-card h4{
  margin-bottom: 10px;
  font-size: 16px;
  text-transform: capitalize;
}
.doc-card p{
  color: #636363;
  border: 1px solid lightgray;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 12px;
  background: #ececec;
}
.doc-card h6{
  font-size: 10px;
  margin: 10px 0px;
  font-weight: 300;
}
.svg-container{
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 600px) {
  .doc-card{
    grid-template-columns: 1fr;
  }
  .doc-card p{
    margin-bottom: 10px;
  }
  .svg-container{
    justify-content: flex-start;
  }
}