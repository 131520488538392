.qte_bg {
  background-color: #f4f7fc;
}
.eigthDay {
  /* width: 900px; */
  margin: auto;
}
.base_eigthDay {
  width: 900px;
  margin: auto;
  position: relative;
}
.Base_days input {
  width: 100%;
  background-color: transparent;
  border: 0px;
  font-size: 14px;
  font-family: "Poppins-Regular";
  padding: 0;
  text-align: center;
  /* transition: all ease 0.1s; */
}
.Acc_Base_days input {
  width: 100%;
  background-color: transparent;
  border: 0px;
  font-size: 14px;
  font-family: "Poppins-Regular";
  padding: 0;
  /* transition: all ease 0.1s; */
}
input.Acc_input {
  border: none;
  background: none;
}
.inData {
  font-size: 12px !important;
  color: #ffffff !important;
  /* color: #8e929a!important */
}
.base_inData {
  font-size: 12px !important;
  color: #8e929a !important;
}
.base_acc_editicon {
  cursor: pointer;
  position: absolute;
  margin-top: 16px;
}
.editicon_pos1 {
  left: -33px;
}
.editicon_pos2 {
  left: 0px;
}
.accHdLn.hide::before {
  background-color: #e1e1e1 !important;
}
.accHdLn.hide input {
  color: #a5a5a5;
}
.Base_days input:focus {
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 11;
  left: 10%;
  bottom: 6px;
  padding: 10px 0px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0px 10px 22px #00277626;
  border-radius: 3px;
  /* transition: all ease 0.1s; */
}
.Acc_Base_days input:focus {
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 11;
  left: 10%;
  bottom: 6px;
  padding: 10px 0px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0px 10px 22px #00277626;
  border-radius: 3px;
  /* transition: all ease 0.1s; */
}
.Acd_hd {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 5px; */
  /* background: #007381; */
  background-color: rgba(0,255,255,.4);
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
}
.Acd_hd .Base_days::before{
  content: none;
}
.Acd_hd .dayBf .inData{
  color:#212121!important;
}
.base_Acd_hd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.accordion_header {
  /* padding-left: 210px;
    padding-right: 290px; */
  padding-left: 225px;
  padding-right: 240px;
}
.base_order_wrapper .accordion_header {
  padding-left: 100px;
  padding-right: 0;
}
.base_accordion_header {
  padding-left: 210px;
  padding-right: 290px;
  position: relative;
}
.base_accordion_header_align {
  padding-left: 210px;
  padding-right: 320px;
  position: relative;
}
.base_order_wrapper .base_accordion_header_align {
  padding-left: 100px;
  padding-right: 0;
}
.base_order_wrapper .base_accordion_header {
  padding-left: 100px;
  padding-right: 0;
}
.base_order_wrapper .Base_ord_wrap section {
  padding: 0;
}
.base_order_wrapper .Base_ord_wrap .base_order_body {
  padding-left: 100px;
  padding-right: 20px;
}
.base_order_wrapper .Base_ord_wrap .Acc_base_order_body {
  padding-left: 100px;
  padding-right: 20px;
}
.Acd_hd section {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.Acd_hd section:nth-child(1) {
  width: 190px;
  flex-shrink: 0;
  margin-right: 10px;
}
.base_order_wrapper .Acd_hd section:nth-child(1) {
  width: 90px;
}
.Acd_hd section:nth-child(2) {
  flex-shrink: 0;
  width: calc(100% - 470px);
}
.base_order_wrapper .Acd_hd section:nth-child(2) {
  width: calc(100% - 110px);
}
.Acd_hd section:nth-child(2).hide {
  opacity: 0;
}
.Acd_hd section:nth-child(3) {
  width: 270px;
  flex-shrink: 0;
  justify-content: flex-end;
}
.base_order_wrapper .Acd_hd section:nth-child(3) {
  width: 20px;
}
.Acd_hd section:nth-child(2) span {
  margin-right: 30px;
}
.base_Acd_hd section {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.base_Acd_hd section:nth-child(1) {
  width: 190px;
  flex-shrink: 0;
  margin-right: 10px;
}
.base_order_wrapper .base_Acd_hd section:nth-child(1) {
  width: 90px;
}
.base_Acd_hd section:nth-child(2) {
  flex-shrink: 0;
  width: calc(100% - 470px);
}
.base_order_wrapper .base_Acd_hd section:nth-child(2) {
  width: calc(100% - 110px);
}
.base_Acd_hd section:nth-child(2).hide {
  opacity: 0;
}
.base_Acd_hd section:nth-child(3) {
  width: 270px;
  flex-shrink: 0;
  justify-content: flex-end;
}
.base_order_wrapper .base_Acd_hd section:nth-child(3) {
  width: 20px;
}
.base_Acd_hd section:nth-child(2) span {
  margin-right: 30px;
}
.accordion_vol {
  /* padding: 10px 4%; */
  text-align: center;
  flex: 1 1;
}
.accordion_vol input {
  width: 40px;
  background: transparent;
  margin: 0 10px;
  border: none;
  border-bottom: 1px solid #002775;
  border-radius: 0;
  text-align: center;
  height: 20px;
}
.btn_gp {
  display: flex;
  align-items: center;
}
.eigthHeader {
  justify-content: center;
}
.eigthHeader .Base_days {
  width: auto;
  flex: none;
}
.eigthHeader .Acc_Base_days {
  width: auto;
  flex: none;
}
.strike {
  text-decoration: line-through;
  color: #818181;
}
.counter_drop_btn {
  width: 18px !important;
  height: 18px;
  background-image: url(../../../assets/images/sale.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}
.counter_cancel {
  margin-right: 0px;
  background-image: url(../../../assets/images/close_blue.svg);
  opacity: 0.4;
}
.counter_drop {
  background-color: white;
  position: absolute !important;
  right: 0px;
  margin: 0;
  z-index: 11;
  top: calc(100% + 6px);
  box-shadow: 1px 0px 15px 0px #00277669;
  border-radius: 3px;
  z-index: 5;
}
.counter_drop li {
  padding: 10px 20px !important;
}
.counter_drop li:hover {
  font-family: "Poppins-Regular";
  background: white;
}
.counter_drop li::before {
  width: 0px !important;
}
.btn_gp div::before {
  /* content:'';
    background-image: url(../../../assets/images/tick.png);
    position: absolute;
    left: 0;
    top: 0;
    height: 18px;
    width: 18px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
}
.accepted {
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.accepted::before {
  position: absolute;
  content: "";
  background-image: url(../../../assets/images/tick.png);
  height: 14px;
  width: 14px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: calc(50% - 7px);
}
.rejected {
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.rejected::before {
  position: absolute;
  content: "";
  background-image: url(../../../assets/images/close.svg);
  height: 14px;
  width: 14px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: calc(50% - 7px);
  opacity: 0.7;
}
.better {
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.better::before {
  position: absolute;
  content: "";
  background-image: url(../../../assets/images/sale.svg);
  height: 14px;
  width: 14px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: calc(50% - 7px);
}
.Accept_offer_button {
  padding: 4px 10px;
  font-size: 12px;
  color: white;
  background-color: #03aa9a;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}
.Reject_offer_button {
  padding: 4px 10px;
  font-size: 12px;
  color: white;
  background-color: #e44e40;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}
.Better_offer_button {
  padding: 4px 10px;
  font-size: 12px;
  color: white;
  background-color: #bfc20f;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}
.label_break {
  /* width: 1px;
    height: 20px;
    background-color: #878787;
    margin: 0px 3px;
    position: relative; */
  margin: 0px 3px;
  position: relative;
  top: -2px;
  color: #878787;
}
.label_break::before {
  position: absolute;
  content: ">>";
  top: -5px;
  font-size: 10px;
  right: 4px;
}
.label_break::after {
  position: absolute;
  content: "<<";
  top: -5px;
  font-size: 10px;
  left: 4px;
}
.strike_out {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.Accord-tog-btn {
  /* position: absolute; */
  width: 15px;
  height: 15px;
  /* top: calc(50% - 10px); */
  /* right: 15px; */
  background-image: url(../../../assets/images/multimedia.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
  transition: all ease 0.5s;
  cursor: pointer;
}
.active .Accord-tog-btn {
  transform: rotate(0deg);
  transition: all ease 0.5s;
}

.disOp {
  color: #58637a47;
}
.opAc .disOp {
  color: black;
}
section.hideAccord {
  display: none;
}
.base_order_body {
  padding-left: 225px;
  padding-right: 240px;
}
.Acc_base_order_body {
  padding-left: 190px;
  padding-right: 330px;
}
.lock {
  position: absolute;
  width: calc(100% - 1px);
  height: 100%;
  left: 0;
  top: 0;
  /* background-color: #f4f7fcde; */
  background-color: rgba(244, 247, 252, 0.75);
  z-index: 3;
  background-image: url(../../../assets/images/lock.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 84% 12%;
  cursor: pointer;
}
.drop_cn_ov {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100% !important;
  background-color: transparent;
  z-index: 4;
}
.no_price {
  font-size: 12px;
}
.modal_btn_wrp {
  display: flex;
  justify-content: space-around;
}
.modal_btn_wrp .modal_btn {
  width: auto;
  padding: 0px 27px;
}