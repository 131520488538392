.tomb_stone{
    color:red;
}
.OrderTableWrap table .seafood_table_head{
    color: #ffffff;
    background-color: #007381;
    border-radius: 5px;
}
.seafood_table_head tr td, tr th {
    text-align: left;
}

table.seafood_table tr td, tr th {
    text-align: left;
}