.nasdaq_week{
    width: '100%';
}
.nasdaq_week_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    margin: 15px 0px 15px;
}
.nasdaq_week p{
    color:#404855;
    font-size: 13px;
    margin:0;
    text-align: center;
}
.nasdaq_week p.time_left_wrap{
    font-size: 11px;
}
.nasdaq_week p.time_left_wrap{
    color: #999;
    margin-top: 10px;
}
.nasdaq_header{
    display: flex;
    justify-content: space-between;
    /* width: 650px; */
    width: 740px;
}
.nasdaq_week_wrap.history_buttons{
    width: 80px;
}
.bubbles {
    height: 35px;
    width: 35px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #002776;
    font-size: 13px;
}
.active_week{
    background: #002776;
    color: #fff;
    cursor: pointer;
}
.inactive_week{
    background: #fff;
    color: #002776;
    cursor: pointer;
}
.inactive_week.no_price{
    background: #fe443877;
    color: #fff;
    cursor:initial;
}
.greyed_out{
    background-color:#d3d1d1;
    color: #fff;
    border: 1px solid #002776;
    /* opacity: 0.3; */

}
.current_week{
    position: relative;
}
.current_week:after{
    content: '';
    position: absolute;
    bottom: -8px;
    border-bottom: 2px solid #007381;
    width: 100%;
    left: 0px;
}
.historyTable td, .historyTable th{
    text-align: right;
}
.historyTable td:first-child, .historyTable th:first-child{
    text-align: left;
}
.time_left_wrap{
    text-align: center;
    font-size: 11.4px;
    color: #999;
    margin-top: 27px;
    margin-left: -5px;
}
.history p{
    /* text-align: right; */
}
@media only screen and (max-width: 1680px) {
    .nasdaq_week p{
        font-size: 13px;
    }
    .nasdaq_week p.time_left_wrap{
        font-size: 11px;
    }
}