.loginWrap{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #00FFFF1a;
    overflow: hidden;
}
.loginOuter{
    width: 1076px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    box-shadow: 0px 36px 20px #00277617;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    overflow: hidden;
}
.loginOuter section{
    width: 50%;
    padding: 100px 90px;
    position: relative;
}
.loginOuter section:nth-child(1){
    background-image: url(../../assets/images/login-box-image.jpg);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    color: #002776;
    display: grid;
}
.logo_landing{
    width: 110px;
    height: 44px;
    background-image: url(../../assets/images/seafood7.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 69px;
}
.loginOuter section:nth-child(1) p{
    font-family: 'Poppins-SemiBold';
    font-size: 26px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.loginOuter section:nth-child(1) label{
    font-family: 'Poppins-Light';
    font-size: 12px;
}
.downloadWrap{
    width: 100%;
    margin-top: 65px;
}
.downloadWrap p{
    font-size: 12px !important;
    font-family: 'Poppins-Regular' !important;
    margin-top: 0px;
    width: 100%;
}
.down_btn_out{
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-family: 'Poppins-Light';
}
.btn_app{
    padding: 6px 0px;
    width: 48%;
    background: #FFFFFF99 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding-left: 55px;
    position: relative;
    cursor: pointer;
    color: #fff;
}
.btn_app .app_ico{
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    left: 20px;
    top: calc(50% - 10px);
}
.btn_app:nth-child(1) .app_ico{
    background-image: url(../../assets/images/android.svg);
}
.btn_app:nth-child(2) .app_ico{
   background-image: url(../../assets/images/apple.svg); 
}

.loginFormHead{
    margin-top: 0px;
    color: #002776;
    font-size: 30px;
    margin-bottom: 0px;
}
.loginFormSubHead{
    color: #404855;
    margin-bottom: 40px;
}
.fP{
    color: #002776;
    font-family: 'Poppins-Light';
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
}
.bub_1{
    position: absolute;
    top: 22%;
    left: 13%;
    opacity: 0.8;
    width: 55px;
}
.bub_2{
    position: absolute;
    bottom: 6%;
    left: 21%;
    opacity: 0.8;
    width: 55px;
}
.bub_3{
    position: absolute;
    bottom: 48%;
    right: 14%;
    opacity: 0.8;
    width: 55px;
}
.l_top_im{
    position: absolute;
    width: 11%;
    left: 0;
    top: -4%;
    opacity: 0.7;
}
.b_rt_im{
    position: absolute;
    width: 11%;
    right: 0;
    bottom:0;
    opacity: 0.7;
    top: 0;
    background: url(../../assets/images/seafood7-element.svg) ;
}
.log_err{
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
    left: 0;
    font-size: 12px;
    color: #ff4e4e;
}
.otp_btn{
    background-color: #002776;
    margin: 10px 0px;
    width: 100%;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 10px;
    text-align: center;
}
.proceed_text{
    margin: 20% 0px;
    text-align: center;
    color: #4CAF50;
}
/* media_query_starts */
@media only screen and (max-width: 1690px) {
    .loginOuter{
        width: 980px;
    }
}
@media only screen and (max-width: 1440px) {
    .loginOuter section {
        padding: 65px 75px;
    }
    .loginFormHead {
        font-size: 25px;
    }
}
@media only screen and (max-width: 1300px) {
    .loginOuter {
        width: 850px;
    }
    .loginOuter section {
        width: 50%;
        padding: 75px 60px;
    }
    .btn_app .app_ico{
        left: 20px;
    }
}
.app-type-chooser {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
