.cp_header{
    color:#002776;
    font-size: 22px;
    font-weight: 400;
}
.edit-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap:40px;
}
.basic_address_wrapper,.dynamic_address_wrapper{
    box-shadow: 0px 5px 10px #ededed;
    padding: 2rem ;
}
.cp_input_wrap{
    margin-bottom: 1rem;
}
.cp_input_header{
    font-size: 15px;
    color: #002776;

}
.cp_short_label{
    font-size: 12px;
}

.cp_input_field{
    margin-top: 0px;
    border-radius: 5px;
    border: 1px solid #002776;
    font-family: "Poppins-Regular";
}
.cp_input_invoce_wrap{
    display: grid;
    grid-template-columns: 1fr 20px;
    place-items: center;
    gap:10px
}

.cp_text_area{
    width: 100%;
    width: -webkit-fill-available;
    padding: 10px;
    font-family: "Poppins-Regular";
}

.dynamic_address_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cp_add_new_wrap{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: space-between;
}
.cp_add_new_wrap span{
    width: 95%;
    height: 0.2px;
    background: rgb(117, 117, 117);
}

.cp_select_block{
    display: block;
}

.cp_select_block select{
    width: 100%;
    margin: 0;
    border: 1px solid #002776;
}

.cp_button_container{
    display: flex;
    gap:20px;
    margin-top: 2rem;
}
.cp_button_container button{
    width: 100px;
    height: 40px;
    font-family: "Poppins-Regular";
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.cp_button_container .cp_primary_btn{
    border: 1px solid #002776;
    background: #002776;
    color: #fff;
}

.cp_button_container .cp_cancel_btn{
    border: 1px solid #002776;
    background: #fff;
}

@media only screen and (min-width: 992px) {
    .edit-wrapper{
        grid-template-columns: 1fr 1fr;
    }

}