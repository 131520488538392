/* *,*::after,*::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
} */
.business_table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.business_header_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap:10px;
    margin-bottom: 15px;
}
.business_header_container p{
    color: #002776;
    font-size: 28px;
    margin-bottom: 2px;
    margin-top: 0px;
    line-height: 100%;
}
.user_action_container{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    align-items: center;
}

.selected_filter_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex:1 0;
}
.selected_filter_wrap span{
    display: flex;
    align-items: center;
    gap:10px;
}

.selected_filter_wrap input{
    display: block;
    width: 20px;
    height: 20px;
    margin:0px;
}

.search_input_container{
    border: 1px solid #aeaeae;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.search_input_container input{
    border: 0;
    margin: 0;
}
.search_input_container .search-icon{
    padding: 5px;
    cursor: pointer;
}
.filter_button_container{
    position: relative;
    cursor: pointer;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    gap:10px;
    border: 1px solid #aeaeae;
    border-radius: 4px;
}
.add_button_container{
    cursor: pointer;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #002776;
    color: #fff;
    border-radius: 4px;
}
.user_management_entity_card_container{
    display: flex;
    gap:20px;
    flex-direction: row;
    /* box-shadow: 0px 4px 20px 0px #0027761C; */
    padding:0px 35px;
    flex-wrap: wrap;
    max-width: fit-content;
    
    margin: 0;
}
.entity_card{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    padding:15px 25px;
    border-radius: 10px;
    cursor: pointer;
}
.entity_card.entity_card_active{
    box-shadow: 0px 4px 20px 0px #0027761C;
}
.entity_card h6{
    font-size: 16px;
    color: #303030;
    font-weight: 400;
    margin: 0;
}
.entity_table_wrap{
    margin-top: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: fit-content;
    padding:15px 10px;
    box-shadow: 0px 4px 20px 0px #0027761C;

}
.entity_table{
    /* width: 100%; */
    min-width: 1000px;
    border-spacing: 0px;
    border-collapse: separate !important;
    border-radius: 6px;
}
.entity_table_head{
    background: #007381;
    color: #fff;
}

.entity_table_head_cell{
    text-align: left;
    vertical-align: middle;
}
.table_id_wrap{
    display: grid;
    grid-template-columns: 5px 1fr;
    place-items: center;
}

.entity_table_body_cell{
    text-align: left;
}
.entity_cell_align{
    display: flex;
    align-items: center;
    gap:10px;
}
.pagination{
    margin: 2rem 0px;
    display: flex;
    align-items: center;
    gap:10px;
}
.pagination_button{
    padding: 4px 12px;
    background-color: #007381;
    color:#fff;
    outline: 0px;
    border: 0px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
}
.disabled_pagination_button{
    padding: 5px 7px;
    background-color: #cccccc;
    border: 1px solid #cccccc;
    color:#fff;
    outline: 0px;
    border-radius: 3px;
    font-weight: 600;
}
.pagination_button_toggles{
    padding: 5px 7px;
    background-color: #fff;
    color:#007381;
    border: 1px solid #007381;
    outline: 0px;
    border-radius: 3px;
    font-weight: 600;
}
.pagination_button_inactive{
    padding: 4px 12px;
    background-color: transparent;
    color:#007381;
    outline: 0px;
    border: 0px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.visible_button_container{
    display: flex;
    flex-direction: row;
    gap:10px;
    /* min-width: 250px; */
    justify-content: space-around;
}
.bp_name_hyperlink{
    display: flex;
    align-items: center;
    gap:10px;
    color: #212121;
}

/*SCROLL*/
.fc_scroll {
    overflow-x: auto;
    overflow: scroll;
    white-space: nowrap;
    overflow-y: auto;
}
.fc_scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.filter_selector_container{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap:20px;
    padding: 25px 15px;
    background: #fff;
    box-shadow: 0 0 0.4em 0 rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    top:3rem;
    right: 0px;
    width: 200px;
    z-index: 1;
}
.btn_cancel{
    background: #fff;
    color: #000;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
    font-family: "Poppins-Regular";
    cursor: pointer;
}

.btn_primary{
    background: #002376;
    color: #fff;
    border: 1px solid #002376;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
    font-family: "Poppins-Regular";
    cursor: pointer;
}
@media only screen and (max-width: 1336px) {
.business_table {
    justify-content: flex-start;
    align-items: flex-start;
}
.user_management_entity_card_container{
    padding: 0;
}
}