.cards{
  width: 23%;
  box-shadow: 0 3px 40px rgb(0 0 0 / 16%);
  border-radius: 5px;
  position: relative;
}

.card_title_wrap{
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_title{
  color: #002776;
  font-size: 17px;
}

.sell_btn{
  background-color: #002776;
  color: white;
  font-size: 10px;
  border-radius: 2px;
  padding: 5px;
  align-items: center;
  /* cursor: pointer; */
  text-align: center;
  justify-content: center;
  width: 30%;
}
.sell_btn:hover{
  background: #4b64b5;
}
.card_body_wrap{
  padding: 10px 10px;
  height: 250px;
  overflow: scroll;
}

.card_item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 5px 0;
}

.card_item p{
  margin: 0;
}

.card_item .move_icon{
  color: #f44336;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 2px 5px;
}

.card_item .move_icon.up{
  color: #4caf50;
}

.card_no_items_wrap{
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%,-50%)
}

.no_items_text{
  font-size: 12px;
  color: #404855;
  font-family: 'Poppins-Light';
  margin: 0;
}