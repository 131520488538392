.week_selection_wrap{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.week_wrap{
    width: 390px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 10px;
    align-items: center;
}
.week_lab_out {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
   
}
.week_lab_out.last{
    margin: 0px;
}
.week_lab_out p{
    color:#404855;
    font-size: 12px;
    margin: 5px 0;
    text-align: center;
}
.text_highlht{
    font-family: 'Poppins-SemiBold';
}
.week_lab{
    font-size: 18px;
    color: #002776;
    background-color: #00ffff26;
    border-radius: 3px;
    min-height: 40px;
    padding: 0px 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
}

.highlht{   
    font-family: 'Poppins-SemiBold';
}
.no_highlht{
    font-family: 'Poppins-Regular';
}
.icon_space{
    margin: 10px;
}
.arrow_icon{
    /* margin-left: 10px; */
    position: relative;
    left: -12px;
    }
.arrow_left_icon{
      position: relative;
      left: 12px;
}
.no_display{
    opacity: 0.3;
}
.hide_icon{
    display: none;
}

.days_wrap{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    position: absolute;
    bottom: -14px;
}
.days{
    height: 13px;
    width: 13px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #002776;
    font-size: 2px;
    /* color: #002776; */
    position: relative;
}
.active_day{
    border: 1px solid #002776;
    background-color: #fff;
}
.days_wrap .thurs{
     border: 1px solid #002776;
     background-color: #00277682;
}

.days_wrap .fri{
    border: 1px solid #002776;
    background-color: #002776;
}
.underline {
    position: absolute;
    top: 14px;
    border-bottom: 2px solid #007381;
    width: 11px;
    left: 0px;
} 
.days_wrap p.days_text_active{
    margin: 0;
    /* position: relative; */
    /* left: 4px; */
    font-size: 8px;
    color: #002776;
}
.days_wrap p.days_text{
     font-size:8px;
     color: #002776;
}
.days_wrap p.days_thurs_text{
    font-size: 8px;
    color: #fff;
}
.days_wrap p.days_fri_text{
    font-size: 8px;
    color: white;
}
.week_lab_box_wrap{
    margin-bottom: 20px;
}

@media only screen and (max-width: 1680px) {
    .week_lab{
        font-size: 16px;
    }
    
    .week_lab_out p{
        font-size: 11px;
    }
}