.quote_btn_selector_container{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    gap:10px;

}
.quote_btn_selector{
    background: #00ffff26;
    display: flex;
    flex: 0.1 1;
    align-items: center;
    justify-content: center; 
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
}

.quote_btn_selector:hover{
    cursor: pointer;
}

.quote_btn_selector input{
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
}

.quote_btn_selector_disabled{
    background: #bfbfbf26;
    display: flex;
    flex: 0.1 1;
    align-items: center;
    justify-content: center; 
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    color: #0707075a;
}
.quote_btn_selector_disabled:hover{
    cursor: not-allowed;
}

.quote_btn_selector_disabled input{
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
}