.OrderTableWrap table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px #ddd;
  overflow: hidden;
}
.OrderTableWrap table .table_head {
  color: #ffffff;
  background-color: #007381;
  border-radius: 5px;
}
.OrderTableWrap table tbody tr:nth-child(even) {
  /* background-color: #F4F7FC; */
}

/* tr td:first-child { opacity: 0.5;}  */

.col {
  opacity: 0.45 !important;
}
.col1 {
  opacity: 1 !important;
}

tr th,
tr td {
  padding: 10px 10px;
  font-weight: normal;
  /* text-align: left; */
  text-align: center;
}
table.quote_row_table tr th,
table.quote_row_table tr td {
  padding: 17px 10px;
}

table.history_table tr td,
table.history_table tr th {
  text-align: right;
}
table.history_table tr td:first-child,
table.history_table tr th:first-child {
  text-align: left;
}
table.outlook_table tr td,
tr th {
  text-align: left;
}
table.calcultor_table tr td,
tr th {
  text-align: center;
}
table.friday_table tr td,
table.friday_table tr th {
  text-align: left;
}
table.outlook_table tr th:nth-child(2) {
  text-align: center;
}
table.friday_table tr th:nth-child(2) {
  text-align: center;
}
table.outlook_table tr td:nth-child(5),
table.outlook_table tr th:nth-child(5) {
  text-align: center;
}
table.calcultor_table tr td:first-child,
table.calcultor_table tr th:first-child {
  text-align: left;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(1) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(2) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(3) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(12) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(16) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(17) td {
  color: #7c7f84;
}
.OrderTableWrap table.calcultor_table tbody tr:nth-child(18) td {
  color: #7c7f84;
}
table tr.no_data td:first-child {
  text-align: center;
}
tr td {
  padding: 12px 10px;
  border-bottom: 1px solid #ddd;
}
td.table_data {
  width: 20%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.OrderTableWrap table.calcultor_table tbody tr {
  /* color:#7C7F84 */
  color: #000;
}
.OrderTableWrap table tbody tr {
  color: #7c7f84;
}
.OrderTableWrap table.costTargetTable tbody tr {
  color: #000;
}
.OrderfilterPanel {
  margin-bottom: 20px;
}
.datePickerButton {
  width: 25px;
  height: 25px;
  background-color: #e1ecfe;
  border-radius: 4px;
  background-image: url(../../assets/images/calendar.svg);
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
}
.datePickerButton input {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
}
.icon-aligner {
  display: flex;
  /* align-items:center; */
  /* justify-content:center; */
}
.expand_icon {
  position: absolute;
  margin-left: 6px;
  cursor: pointer;
}
