.matrix_header_wrap{
    display: flex;
    justify-content: space-between;
    /* margin-top: 30px; */
    width: 100%;
    margin-right: 30px;
    /* margin-bottom: 30px; */
}
.header_box{
    font-size: 16px;
    color: #002776;
    background-color: #00ffff26;
    border-radius: 3px;
    min-height: 40px;
    padding: 0px 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    /* font-family: 'Poppins-Medium'; */
}
.header_box_text{
    color:#404855;
    font-size: 12px;
    /* margin: 5px 0; */
    text-align: center;
}
.no_highlight{
    font-family: 'Poppins-Medium'; 
}
  