.rfq_accordion_header{
  padding-left: 270px;
  padding-right: 290px;
}
.rfq-view-modal{
  padding: 20px;
}

.rfq-view-modal .close-icon{
  padding: 10px;
}

.rfq-view-modal .bottom_statics_box{
  grid-template-columns: 1fr;
}
.toggle-on{
  /* transform: rotate(90deg); */
  animation: rotate90 1s forwards;
}

.table-new{
  width: 100%;
  border: 1px solid gray;
  border-spacing: 0px;
}
.table-new thead{
  background: #007381;
  color: #fff;
}

.table-new th{
  text-align: center;
}
.td-hog{
  text-align: left;
}
.tr-box td{
  font-weight: 600;
}
.td-kg {
 text-align: right;
}
.view-purchase-table,.view-purchase-table th,.view-purchase-table td{
  text-align: left;
}
.volume-input{
  margin: 0px;
  text-align: right;
  width: 100px;
  font-weight: 500;
  font-size: 16px;
}

.volume-input-disabled{
  border: 0px;
  text-align: center;
  margin: 0px;
  width: 100px;
  font-weight: 500;
  font-size: 16px;
}
tfoot.view-purchase-footer {
  background: rgba(82, 219, 235, 0.4);
}
tfoot.view-purchase-footer tr th {
  font-weight: bold;
}

@keyframes rotate90 {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(90deg);
  }
}