@font-face {
  font-family: "Poppins-Regular";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Poppins-Bold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(./assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "Poppins-Light";
  src: url(./assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}
html {
  height: 100%;
}
body {
  margin: 0;
  /* height: 100%; commented due to scrolling in business page creating preprospect */
  font-size: 14px;
  font-family: 'Poppins-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main,
#root {
  height: 100%;
}
div,
form,
section,
input {
  box-sizing: border-box;
}
img {
  width: 100%;
}
input {
  outline: none;
}
p {
  line-height: 100%;
}
.flx {
  display: flex;
  justify-content: space-between;
}
.in_pg {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-top: 100px;
  background-image: url(./assets/images/interface.svg);
  background-size: cover;
  background-position: center;
}
.back_link {
  color: #002776;
  font-family: "Poppins-Light";
  margin-top: 20px;
}
.Logout {
  position: fixed;
  right: 80px;
  top: 34px;
  font-weight: 600;
  color: #8cc6ec;
  cursor: pointer;
}
.body_wrap {
  height: 100%;
  margin-left: 250px;
  margin-top: 80px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 10px;
}
.rel_div {
  position: relative;
}
.cmn_head {
  height: 40px;
}
.cmn_head p {
  color: #002776;
  font-size: 28px;
  margin-bottom: 2px;
  margin-top: 0px;
  line-height: 100%;
}
.cmn_head label {
  color: #58637a;
  font-size: 16px;
}
.toasts-container {
  box-shadow: 0px 0px 0px 0px #00000026 !important;
  z-index: 999;
  bottom: 20px !important;
}
.toast-error {
  border: 1px solid #ff7575;
  border-left: 4px solid #ff7575 !important;
  background-color: white !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  color: #f29c9c !important;
  padding: 10px 37px !important;
}
.toast-success {
  border: 1px solid green;
  border-left: 4px solid green !important;
  background-color: white !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  color: green !important;
  padding: 10px 37px !important;
}
.cont_wp {
  height: 100%;
  /* overflow: hidden; */
}
.cont_wp .scrl_out {
  height: calc(100% - 70px);
  /* overflow: hidden; */
  overflow-y: auto;
  padding: 15px;
  /* padding-bottom: 30px; */
  padding-bottom: 100px;
}
.Back_link {
  color: #58637a;
  font-size: 16px;
  margin-top: 0px;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.Back_link::before {
  content: "<";
  width: 10px;
  height: 10px;
  left: 0;
  position: absolute;
}
.Cmn_in_head {
  font-size: 24px;
  color: #404855;
  margin: 0px;
  margin-bottom: 10px;
}
.Cmn_in_head span {
  color: #58637a;
  font-size: 20px;
}
.Cmn_in_label {
  font-size: 14px;
  color: #404855;
  font-family: "Poppins-Light";
}
.Cmn_in_label span {
  margin: 0px 10px;
}
/* Scroll Bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #002776;
}
::-webkit-scrollbar-thumb:hover {
  background: #002776;
}
.drop_arrow {
  position: absolute;
  width: 16px;
  height: 16px;
  box-shadow: 0px 3px 6px #00277647;
  background-color: #ffffff;
  border-radius: 50%;
  right: 10px;
  top: calc(50% - 8px);
  cursor: pointer;
  background-image: url(./assets/images/arrow-down-sign-to-navigate.png);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.addrDrop {
  position: absolute;
  width: 100%;
  background-color: white;
  top: 107%;
  left: 0;
  box-shadow: 0px 3px 6px #00277647;
  border-radius: 3px;
  z-index: 11;
  max-height: 120px;
  overflow: hidden;
  overflow-y: scroll;
}
.addrDrop p {
  margin: 0;
  padding: 10px 10px;
  cursor: pointer;
}

/* media_query_starts */
@media only screen and (max-width: 1680px) {
  .drop_arrow {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
  }
  .Cmn_in_label {
    font-size: 12px;
  }
  .Cmn_in_head {
    font-size: 20px;
  }
  .Cmn_in_head span {
    font-size: 16px;
  }
  .cmn_head p {
    font-size: 24px;
  }
  .cmn_head label {
    font-size: 12px;
  }
  .Back_link {
    font-size: 14px;
  }
}

/*UniversalMatrix Styling*/
.dimension-selector {
  margin-left: 0px;

  display: flex;
  align-items: center;
}
.dimension-selector p,
.dimension-selector span input {
  margin: 0 10px;
}
.dimension-selector span {
  display: grid;
  place-items: center;
}
.dimension-selector span input {
  width: 20px;
}
.dimension-selector label {
  font-size: 12px;
  color: #58637a;
}
.refresh-btn {
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-left: 10px;
}
.refresh-btn:hover {
  cursor: pointer;
}
.cancel_button {
  display: flex;
  height: 40px;
  background: #fff;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 25px;
  border: 1px solid #002776;
  /* box-shadow: -1px 0px 7px 2px #0027764f; */
  color: #002776;
  transition: all ease 0.5s;
}
.cancel_button:hover {
  cursor: pointer;
}
.pointer:hover {
  cursor: pointer;
}
.icon-container {
  display: flex;
  justify-content: flex-start;
}
.icon-btn {
  padding: 10px;
  background: rgba(136, 192, 199, 0.3);
  font-size: 22px;
  border-radius: 50%;
  color: #007381;
  margin: 0px 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
}
.icon-btn:hover {
  cursor: pointer;
}
.table-header {
  background: #007381;
  height: 50px;
}

.total-row-table {
  display: grid;
}
.fc_scroll {
  overflow-x: auto;
  overflow: scroll;
  white-space: nowrap;
  overflow-y: auto;
}
.fc_scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rfQ_fc_manager .bottom_statics_box,
.purchase_fc_detail .bottom_statics_box {
  grid-template-columns: 1fr;
}
.tender_fc_manager .bottom_statics_box,
.purchase_fc_detail .bottom_statics_box {
  grid-template-columns: 1fr;
}
.rfQ_fc_manager .header_button_main {
  grid-template-columns: 1fr;
  justify-self: end;
}

/* .rfq_fc_page_js .bottom_statics_box {
  grid-template-columns: 75% 25%;
} */

.rfq_fc_page_js .wrap_btn_drop {
  grid-template-columns: 45% 50%;
}

.rfQ_fc_manager .bottom_statics_box .display_week,
.purchase_fc_detail .bottom_statics_box .display_week {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.tender_fc_manager .bottom_statics_box .display_week,
.purchase_fc_detail .bottom_statics_box .display_week {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.view_purchase_fc_issue_re_init .wrap_btn_drop {
  grid-template-columns: 58% 37%;
}

.view_purchase_fc_issue_re_init .header_button_main {
  grid-template-columns: 1fr;
  max-width: 180px;
  justify-self: end;
}
.multi-select div {
  inset: auto !important;
  margin: initial;
}
.refiner_fc {
  display: grid;
  grid-gap: 10px;
}
.react-multi-select__menu-list.react-multi-select__menu-list--is-multi {
  font-size: 15px;
  /* padding: 5px; */
  min-width: 500px;
  border: solid hsl(0, 0%, 80%) 1px;
}
.react-multi-select__menu-list.react-multi-select__menu-list--is-multi div {
  font-size: 15px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.react-multi-select__menu-list.react-multi-select__menu-list--is-multi
  div
  input[type="checkbox"] {
  position: relative;
  top: -4px;
}
@media (max-width: 767px) {
  .rfQ_fc_manager .bottom_statics_box .display_week,
  .purchase_fc_detail .bottom_statics_box .display_week {
    grid-template-columns: 1fr;
  }
  .tender_fc_manager .bottom_statics_box .display_week,
  .purchase_fc_detail .bottom_statics_box .display_week {
    grid-template-columns: 1fr;
  }

  .rfq_fc_page_js .bottom_statics_box,
  .view_purchase_fc_issue_re_init .bottom_statics_box {
    grid-template-columns: 1fr;
  }

  .rfq_fc_page_js .bottom_statics_box .splitter_display {
    grid-template-columns: 1fr 1fr;
  }
  .rfq_fc_page_js .wrap_btn_drop {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .rfQ_fc_manager .bottom_statics_box .display_week,
  .view_purchase_fc_issue_re_init .bottom_statics_box .display_week,
  .purchase_fc_detail .bottom_statics_box .display_week {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .rfq_fc_page_js .bottom_statics_box,
  .purchase_details_fc .bottom_statics_box,
  .view_purchase_fc_issue_re_init .bottom_statics_box {
    grid-template-columns: 1fr;
  }
  .rfq_fc_page_js .bottom_statics_box .display_week,
  .purchase_details_fc .bottom_statics_box .display_week {
    grid-template-columns: 1fr 1fr;
  }

  .tender_fc_manager .bottom_statics_box .display_week,
  .purchase_fc_detail .bottom_statics_box .display_week {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rfq_fc_page_js .bottom_statics_box,
  .rfq_fc_page_js .bottom_statics_box,
  .view_purchase_fc_issue_re_init .bottom_statics_box {
    grid-template-columns: 1fr;
  }
  .rfq_fc_page_js .bottom_statics_box .display_week,
  .purchase_details_fc .bottom_statics_box .display_week,
  .view_purchase_fc_issue_re_init .bottom_statics_box .display_week {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .rfq_fc_page_js .bottom_statics_box,
  .purchase_details_fc .bottom_statics_box {
    grid-template-columns: 80% 20%;
  }

  .view_purchase_fc_issue_re_init .bottom_statics_box {
    grid-template-columns: 1fr;
  }
}
.mod_lab_out p {
  min-height: 12px;
}

.rfq_fc_page_js .header_box {
  font-size: 15px;
}

.rfq_fc_page_js .mod_lab_out p {
  min-height: 26px;
}

.set_universal_aligment_fx .bottom_statics_box .display_week {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.fc_stream_wrapper {
  position: absolute;
  bottom: 0;
  right: 3px;
  opacity: 0.35;
}

.fc_stream_wrapper .wrg-toggle-circle {
  background-color: lightblue;
  opacity: 0.4;
}

.fc_stream_wrapper .wrg-toggle--checked .wrg-toggle-container {
  background-color: transparent !important;
}

.fc_base2,
.fc_base3,
.fc_base4 {
  padding: 30px 20px 20px 30px !important;
}

.fc_base2 .Base_total_block,
.fc_box_289 {
  box-shadow: 0 4px 20px rgb(0 39 118 / 11%);
  border-radius: 5px;
  margin-right: 15px;
}



/*OVERRIDES STYLES*/
.mod_lab_out{
  position: relative;
  display: grid;
  flex-direction: column;
  /* justify-content: flex-end; */
  grid-template-rows: 1fr 1fr 1fr;
}
.mod_lab_out p{
  text-align: center;
}

.rfq-manager-table .producer-head{
  text-align: left;
}
.react-multi-select__menu-list.react-multi-select__menu-list--is-multi div input[type="checkbox"]{
  width: auto!important;
}