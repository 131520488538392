.shipment-heading{
  color:#007381
}
.shipment_list li a{
  display: grid;
  grid-template-columns: 80px 90px 80px;
  gap:5px;
  color:#000;
}
.shipment_list li a:hover{
  color:#007381;
}
.shipment_list li a p{
  position: relative;
}
.shipment_list li a p::after{
  content:'';
  position: absolute;
  height: 100%;
  width: 2px;
  right: 0;
  background: #000;
}
.shipment_list li a p:last-child::after{
  display: none;
}