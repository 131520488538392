.priceButtonWrap{
    width: 650px;
}
.priceListBlock{
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #7C7F84;
}
.price_items_align{
    justify-content: center;
    
}
.priceListBlock:nth-child(odd) {
    background-color: #F4F7FC;
}
.no_backgrnd .priceListBlock{
    background: none;
}
.priceListBlock div:first-child{
    text-align: left;
}
.priceListBlock div:nth-child(2){
    color: #333;
}
.priceListBlock div{
    text-align: right;
}
.priceListeur{
    /* font-size: 14px; */
    text-align: center;
}
.priceListusd{
    /* font-size: 14px; */
    text-align: right;
}
.priceListBlock div.list_price_eur{
    /* padding-left: 40px; */
}
.priceListBlock div.list_price_usd{
    /* padding-left: 101px; */
}
.priceListBlock.boldHead{
    color: #58637A;
    font-family: 'Poppins-Medium';
    background: #E5EBF8;
    margin-bottom: 10px;
}
.price_input {
    text-align: right;
    margin: 0;
    font-size: 16px;
}
.price_no_data{
    font-size: 12px;
    color: #404855;
    font-family: 'Poppins-Light';
    text-align: center;
    margin: 20px;
}
.history_icon{
    margin-left: 10px;
}