.home_top_wrap{
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
}
.home_top_wrap:nth-child(1){
    margin-bottom: 30px;
}
.home_top_wrap:nth-child(2){
    margin-bottom: 30px;
}
.home_top_wrap .home_top_block{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #0027761C;
    border-radius: 8px;
}
.home_top_wrap .home_top_block:nth-child(1){
    /* width: 49%; */
}
.home_top_wrap .home_top_block:nth-child(2){
    background-image: url(../../../assets/images/seafood7-element.svg);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.home_top_wrap .home_top_block:nth-child(1) p{
    font-size: 24px;
    color: #404855;
    margin: 0px;
    margin-bottom: 10px;
}
.home_top_wrap .home_top_block:nth-child(2) p{
    font-size: 12px;
    color: #404855;
    margin: 0px;
    margin-bottom: 10px;
}
.home_top_wrap .home_top_block:nth-child(1) label{
    font-size: 12px;
    color: #56595e;
    font-family: 'Poppins-Regular';
}
.home_top_wrap .home_top_block:nth-child(2) label{
    font-size: 14px;
    color: #404855;
    font-family: 'Poppins-Light';
}
.createNoteTitleWrap{

    display: flex;
    justify-content: space-between;
}
.home_bt_out_wp{
    display: flex;
    margin-top: 25px;
    position: relative;
    align-items: center;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
}
.button_circle{
    background-color: #00FFFF26;
    color: #002776;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
}
.no_price{
    text-align: center;
}
.home_btn{
    background-color: #00FFFF2D;
    background: #00FFFF2D;
    border-radius: 4px;
    color: #002776;
    font-size: 16px;
    padding: 13px 25px;
    cursor: pointer;
    transition: all ease 0.5s;
    width: 100px;
    text-align: center;
}
.badge {
    width: auto;
    margin-top: 10px;
    padding:5px;
    border-radius: 20px;
    font-size: 12px!important;
}
.warning{
    background-color: #FFD695;
    color: #fff;
}
.order-btn{
    width: auto;
    background-color: #007681;
    margin-top: 10px;
    color: #fff;
    padding: 10px;
}

.home_btn:nth-child(1){
    margin-right: 20px;
}

.button_circle:hover,.home_btn:hover{
    background-color: #002776;
    box-shadow: -1px 0px 7px 2px #0027764f;
    color: white;
    transition: all ease 0.5s;
}
.home_blk_price label{
    color: #002776;
}
.home_blk_price p{
    color: #58637A;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    margin-top: 5px;
}
.home_blk_price_wrap{
    display: flex;
}
.home_blk_price{
    margin-right: 45px;
}
.home_blk_stat_wrap{
    display: block;
    /* align-items: flex-start; */
}
.count_tag{
    background-color: #0027762B;
    color: #002776;
    display: inline-block;
    padding: 1px 10px;
    border-radius: 26px;
}
.home_blk_stat_wrap .title p{
    color: #002776;
    font-size: 26px;
    font-family: 'Poppins-Light';
    margin: 15px 0px;
}
.home_blk_stat_wrap label{
    color: #58637A;
    font-size: 16px;
    font-family: 'Poppins-Light';
}
.home_conf_outer{
    width: 100%;
    display: flex;
}
.home_conf_status{
    background-color: #2BC0BA;
    color: white;
    padding: 2px 20px;
    border-radius: 36px; 
    margin-top: 15px;
    margin-right: 20px;
}
.req_status{
    background-color: #FFD695;
}

.sp_btn,.revReq{
    background-color: #007681 !important;
    color: white !important;
}
.disabled_btn-container{
    opacity:0.45;
}
.disabled_btn{
    background-color: #00FFFF2D;
    background: #00FFFF2D;
    border-radius: 4px;
    color: #002776;
    font-size: 14px;
    padding: 10px 25px;
    transition: all ease 0.5s;
    width: 100px;
    text-align: center;
    /* margin-right: 10px; */
}

.disabled_btn.disable{
    background-color:rgba(0, 255, 255, 0.1) ;
}

.disabled_btn:hover{
    background-color: #002776;
    box-shadow: -1px 0px 7px 2px #0027764f;
    color: white;
    transition: all ease 0.5s;  
}
.not-allowed{
    cursor: not-allowed;
}
.flight_ico{
    width: 40px;
    height: 20px;
    background-image: url(../../../assets/images/icon-flight.svg);
    margin: 2px;
    display: inline-block;
    vertical-align: middle;
    /* background-size: contain; */
    background-position: center;
    background-repeat: no-repeat;
}
.dashboard-grid-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.dashboard-grid-wrap .dashboard-grid-wrap-itme {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #0027761c;
    border-radius: 8px;
}
table.shipment-table-new caption {
    padding: 5px;
    font-weight: 600;
}


/* media_query_starts */
@media only screen and (max-width: 1680px) {
    .home_top_wrap .home_top_block:nth-child(1) p{
        font-size: 20px;
    }
    .home_top_wrap .home_top_block:nth-child(1) label{
        font-size: 12px;
    }
    .home_top_wrap .home_top_block:nth-child(2) p{
        font-size: 12px;

    }
    .home_top_wrap .home_top_block:nth-child(2) label{
        font-size: 12px;
    }
    .home_btn{
        font-size: 14px;
    }
    .home_blk_price label{
        font-size: 12px;
    }
    .home_blk_price p{
        font-size: 20px;
    }
    .count_tag{
        font-size: 12px;
    }
    .home_blk_stat_wrap p{
        font-size: 22px;
    }
    .home_blk_stat_wrap label{
        font-size: 14px;
    }
    .home_conf_status{
        font-size: 12px;
    }

}
@media only screen and (max-width: 1390px) {
    .home_top_wrap .home_top_block{
        padding: 25px;
    }
    .home_btn{
        padding: 10px 20px;
    }
    .home_blk_price{
        margin-right: 30px;
    }
    .home_blk_price p {
        font-size: 18px;
    }
    .home_blk_stat_wrap p{
        font-size: 20px;
    }
    .home_blk_stat_wrap label{
        font-size: 12px;
    }
    .home_blk_stat:nth-child(1){
        margin-right: 20px;
    }
    .home_blk_stat{
        margin-top: 20px;
    }
}


.discussion-container{
    display: flex;
    gap:30px;
    height: 100%;
}

.icon-container{
    gap:10px;
}

.table-icon{
    display: flex;
    align-items: flex-end;
}

.discussion-section{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

}
.discussion-section:not(:last-child)::after{
    content: '';
    height: 100%;
    width: 1.5px;
    background: #dadada;
    position: absolute;
    top:0;
    right:-15px;
}

.discussion-container h4{
    margin: 0px 0px 10px 0px;
    color:#989898;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-size: 13px;
}
.icon-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    padding: 8px 12px;
    border-radius: 7px;
}
.icon-card h5{
    margin: 0;
    font-weight: 600;
    font-size: 17px;
}
.timestamp-table-cell{
    display: flex;
    align-items: center;
    gap:10px
}
.timestamp-table-cell .new-badge{
    background: rgba(142, 204, 212,0.3);
    padding: 3px 10px;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 600;
}
.pagination-number-container{
    display: flex;
    gap:5px;
    align-items: center;
    margin-top: 15px;
    border-bottom: 0px;
}
.pagination-numbers{
    padding: 5px 10px;
    border-radius: 5px;
    background: rgba(142, 204, 212,0.1);
    cursor: pointer;
    color: #212121;
}
.pagination-active{
    background: rgba(142, 204, 212,1);
    color:#fff;
}
.d-flex{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.quotes-card{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.button_disabled_price{
    background-color:rgba(0, 118, 129,0.3);
    cursor: not-allowed;
    padding:10px;
    border-radius: 10px;
    color: #fff;
}