.sell_body_wrap{
  height: 100%;
  padding-left: 270px;
  padding-top: 100px;
  box-sizing: border-box;
  padding-right: 20px;
  padding-bottom: 20px;
}

.cmn_head_align{
display: flex;
justify-content: space-between;
align-items: center;
}
.next_button{
height: 32px;
background: #002776;
align-items: center;
justify-content: center;
display: flex;
width: 100px;
border-radius: 5px;
position: relative;
color: white;
}
p.next_link{
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin: 0;
  text-align: center;
  margin-top: 2px;
}
.next_icon{
  display: flex;
  /* /* align-items: center; */
  /* flex-direction: row; */
  position: absolute;
  /* right: 5px; */
}
.next_icon_align{
  right: 5px;
}
.card_con{
display: flex;
flex-direction: row;
justify-content: flex-start;
margin-bottom: 25px;
}
.cards{
  width: 25%;
  margin: 0px 5px;
  /* margin-right: 25px; */
  box-shadow: 0 3px 40px rgb(0 0 0 / 16%);
  border-radius: 5px;
  position: relative;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* margin: 0px 20px 0px; */
}
.card_title_wrap{
padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.remove_btn{
  background-color: #002776;
  color: white;
  font-size: 10px;
  border-radius: 2px;
  padding: 5px;
  align-items: center;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  width: 30%;
}
.card_body_wrap{
padding: 10px 10px;
height: 250px;
overflow: scroll;
}
.card_item{
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #f5f5f5;
padding: 5px 0;
}
.card_title{
color: #002776;
font-size: 17px;
}
.card_no_items_wrap{
/* display: flex;
height: 250px;
align-items: center;
justify-content: center; */
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%,-50%)
}
.card_item p{
margin: 0;
}
.card_item .move_icon{
color: #f44336;
font-size: 16px;
font-weight: bold;
cursor: pointer;
padding: 2px 5px;
}
.card_item .move_icon.up{
color: #4caf50;
}
.no_items_text{
font-size: 12px;
color: #404855;
font-family: 'Poppins-Light';
margin: 0;
}
.modal_text{
font-size: 14.5px;
font-family: 'Poppins-Regular';
/* margin: 0;
margin-bottom: 12px; */
}
.modal_text:last-child{
margin-bottom: 25px;
}
.modal_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;
}
.modal_heading p{
color: #002776;
font-size: 22px;
margin-bottom: 2px;
margin-top: 0px;
line-height: 100%;
}
.close-icon{
  cursor: pointer;
  position: absolute;
  right: 19px;
}
.home_icon{
    position: relative;
    right: 6px;
    top: 3px;
}
.modal_matrix{
  padding-top: 20px;
  padding-right: 15px;
}
.updateBtnCon{
  position: absolute;
  right: 50px;
}
.hide_matrix{
  display: none;
}