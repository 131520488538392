.shipment-table-new{
  width: 100%;
  border: none;
  border-spacing: 0px;
}
 
.shipment-table-new thead{
  background: #007381;
  color: #fff;
}

.shipment-table-new th,.shipment-table-new td{
  text-align: left;
}

.timeline h4,.timeline p{
  margin: 0;
  padding: 0;
}