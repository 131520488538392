.side_bar_wrap{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    /* background-color: #F4F7FC; */
    width: 240px;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 0.1fr 1fr 0.1fr;
}
/* .sideLogo{
    background-image: url(../../assets/images/seafood7.svg);
    margin-left: 70px;
    width: 110px;
    height: 44px;
    margin-top: 15px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
} */
.sidebar-logo-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectUserByAdmin{
    height: 46px;
    border: 0px solid;
    margin-right: 30px;
    background-color: white;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    color: #404855;
    outline: none;
    padding: 0px 10px;
}
.sideMenu{
    list-style: none;
    padding-left: 0px;
    overflow: auto;
    /* margin-top: 60px; */
}
ul.sideMenu .menu_item{
    padding: 18px 0px;
    position: relative;
    font-size: 14px;
    color: #58637A;
    cursor: pointer;
    padding-left: 35px;
    line-height: 100%;
    transition: all ease 0.5s;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap:10px
}
.activeNav .menu_item, ul.sideMenu .menu_item:hover{
    color: #002776 !important;
    font-family: 'Poppins-Medium';
    background-color: #FFFFFF;
    transition: all ease 0.5s;
    background: rgb(233,235,255);
    background: linear-gradient(90deg, rgba(0,255,255,0.25) 0%, rgba(0,255,255,0.15) 42%, rgba(255,255,255,1) 100%);
}
.activeNav .menu_item::before, ul.sideMenu .menu_item:hover::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 3px;
    background-color: #002776 !important;
    transition: all ease 0.5s;
}

.activeNav .menu_item svg.sidebar_icon,ul.sideMenu .menu_item:hover svg{
    color: #002776 !important;
    transition: 0.2s ease-in-out;
}


 a{
    text-decoration: none !important;
}
.header{
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
    align-items: center;
    /* background-color: black; */
    background-color: #FFFFFF;
    /* box-shadow: 0 3px 40px rgba(0,0,0,.16); */
    padding: 10px;
}

.pro_name{
    background-color: #FFFFFF;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    padding: 15px;
    padding: 0px 40px 0px 70px;
    color: #404855;
    position: relative;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    background-image: url(../../assets/images/arrow-down-sign-to-navigate.png);
    background-size: 8px;
    background-position: 90% 19px;
    background-repeat: no-repeat;
}
.logout_pop{
    padding: 10px 25px;
    background-color: #FFFFFF;
    border-radius: 5px;
    position: absolute;
    right: 0;
    line-height: 100%;
    bottom: -45px;
    box-shadow: 0px 3px 10px 0px #00277626;
    z-index: 3;
    cursor: pointer;
    font-size: 14px;
}
.pro_ico{
    width: 30px;
    height: 30px;
    top: 8px;
    position: absolute;
    left: 20px;
    border-radius: 50%;
    /* background-image: url(../../assets/images/dummy-profile.png); */
    background-color: #002776;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: bold;
    color: white;
}
.notif_btn{
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    height: 46px;
    width: 46px;
    margin-right: 30px;
    background-size: 20px;
    background-image: url(../../assets/images/bell_act.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.nav_ico{
    position: relative;
    /* left: 40px; */
    background-image: url(../../assets/images/business.png);
    /* top: calc(50% - 8px); */
    display: flex;
    align-items: center;
}
.subMenu_con{
    /* display: none; */
    /* margin-top: 10px; */
}
.active_route .subMenu_con{
    display: block;
    /* max-height: 200px;
    overflow: scroll; */

}
.menu_item.sub_menu_item {
    margin-left: 0px;
}
.visible{
    display: block;
}
.inactive_route{
    display: none;
}


ul.sideMenu  .nav_item li:hover .subMenu_con{
    display: block;
}
/* .nav_item:hover {
    background-color: white;
} */
.activeNav .menu_item, ul.sideMenu .menu_item .outer_con:hover{
    background-color: white;
}
/* ul.sideMenu a:nth-child(1) .menu_item .nav_ico{
    background-position: 445px 102px;
}
ul.sideMenu li a:nth-child(1) .buy .nav_ico{
    background-position: 368px 103px;
}
ul.sideMenu li a:nth-child(1) .sell .nav_ico{
    background-position: 214px 103px;
} */
ul.sideMenu li:nth-child(1) .nav_ico{
    /* background-position: 368px 103px; */
}
/* ul.sideMenu a:nth-child(2) .menu_item .nav_ico{
    background-position: 368px 103px;
} */
ul.sideMenu a:nth-child(3) .menu_item .nav_ico{
    width: 24px;
    /* left: 37px; */
    height: 21px;
    background-image: url(../../assets/images/business.png);
}
ul.sideMenu a:nth-child(3) .menu_item:hover .nav_ico{
    background-image: url(../../assets/images/business.png);
}
/* ul.sideMenu a:nth-child(4) .menu_item .nav_ico{
    background-position: 214px 103px;
}
ul a:nth-child(5) .menu_item .nav_ico{
    background-position: 135px 103px;
}
ul a:nth-child(6) .menu_item .nav_ico{
    background-position: 55px 103px;
} */
ul.sideMenu a:nth-child(3).activeNav .menu_item .nav_ico{
    background-image: url(../../assets/images/business.png);
}
.sub_menu_item .nav_ico{
    display: none;
}
ul.sideMenu  .subMenu_con a:nth-child(1) .nav_icon {
    background-image: url(../../assets/images/inactive-calendar.svg);
    background-size: cover;

}
ul.sideMenu  .subMenu_con a:nth-child(1):hover .nav_icon {
    background-image:  url(../../assets/images/active-calendar.svg);
}
ul.sideMenu .subMenu_con a:nth-child(1).activeNav .nav_icon {
    background-image: url(../../assets/images/active-calendar.svg);
}


ul.sideMenu  .subMenu_con a:nth-child(2) .nav_icon {
    background-image: url(../../assets/images/inactive-calendar.svg);
    background-size: cover;

}
ul.sideMenu  .subMenu_con a:nth-child(2):hover .nav_icon {
    background-image:  url(../../assets/images/active-calendar.svg);
}
ul.sideMenu .subMenu_con a:nth-child(2).activeNav .nav_icon {
    background-image: url(../../assets/images/active-calendar.svg);
}
ul.sideMenu .sub_menu_item .menu_item{
    padding-left: 90px;
}
.sfn-logo{
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom:5px
}

.sfn-logo img{
    width: 60px;
}

ul.menu{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-right: 8px;
  margin-left: 8px;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
}
ul.menu li{
    float: left;
}
ul.menu li{
    /* display: grid;
    grid-auto-flow: column;
    place-items: center;
    color: #404855;
    text-align: center;
    padding: 5px 5px; */
    margin: 0px 5px;
    text-decoration: none;
    /* font-size: 16px; */
    cursor: pointer;
  }
  
  ul.menu li:hover{
      color:#002776;
      font-family: 'Poppins-Medium';
  }
.shaded_btn{
    border-radius: 4px;
    color: #c8cbd1;
    font-size: 14px;
    /* padding: 10px; */
    transition: all ease 0.5s;
    /* width: 50px; */
    text-align: center;
    margin:0px 10px;
}
.shaded_btn:hover{
    color: #818489;
    transition: all ease 0.5s;  
}

.logout-ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 8px;
    margin-left: 8px;
    display: grid;
    grid-auto-flow: column;
    place-items: center;
   
    padding: 10px;
}

.logout-ul li span{
    cursor: pointer;
}

.logout-ul li .dropdown{
    display: none;
    list-style: none;
    position: absolute;
    right: 20px;
    z-index: 1;
    padding:5px 8px;
    cursor: pointer;
}
.logout-ul li .dropdown li{
    background-color: #FFFFFF;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    padding: 0px 40px 0px 70px;
    color: #404855;
    position: relative;
    height: 46px;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logout-ul li:hover .dropdown{
    display: block;
}
.logout-ul li:hover .dropdown li:hover{
    color:red
}