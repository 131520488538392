.producer-cell svg{
vertical-align: top;
margin-left: 5px;
}
.producer-cell svg:hover{
  cursor: pointer;
}

.disabled{
  opacity: 0.3;
}
.disabled:hover{
  cursor: not-allowed;
}