.nasdaq_week_wrapper{
    margin-bottom: 20px;
    margin-top: -5px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.week_selc_btn{
    border: none;
    /* outline: none;
  
    border-radius: 2px;
    margin-right: 8px;
    width: 38px;
    height: 35px; */
    outline: none;
    /* padding: 10px; */
    border-radius: 50px;
    margin-right: 8px;
    margin-right: 12px;
    width: 35px;
    height: 35px;
    position: relative;
  
}
.double_arrow_right {
    position: relative;
    top: 6px;
    margin-left: 1px;
}
.double_arrow_left{
    position: relative;
    top: 6px;
    margin-right: 12px;
}
.input_checkbox {
    width: 19px;
    padding-top: 29px;
    /* margin-top: -7px; */
    position: relative;
    top: 11px;
    left: 8px;
}
.calender_icon {
    width: 25px;
    height: 25px;
    padding-top: 12px;
    /* margin-top: -7px; */
    position: relative;
    top: 11px;
    left: 15px;
    cursor: pointer;
    color: '#002776';
}
.clock_icon {
    width: 20px;
    height: 20px;
    /* padding-top: 12px; */
    /* margin-top: -7px; */
    position: relative;
    /* top: 0px; */
    left: 5px;
}