.rfq-view-modal{
  padding: 20px;
}

.rfq-view-modal .close-icon{
  padding: 10px;
}

.rfq-view-modal .bottom_statics_box{
  grid-template-columns: 1fr;
}