.user_detail_section *,.user_detail_section *::after, .user_detail_section::before{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.back_btn{
  display:flex;
  align-items:center;
  color:'#ACACAC';
  margin-bottom: 10px;
  cursor: pointer;
}
.ud_heading{
  color:#002776;
  font-size: 22px;
  font-weight: 400;
}
.ud_contents{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap:15px;
  padding: 15px;
  box-shadow: 0 0 0.4em 0 rgb(0 0 0 / 20%);
}
.ud_basic_container{
  box-shadow: -3px 0px 1px rgb(0 0 0 / 5%);
  padding: 10px;
}
.users_list{
  max-height: 80vh;
  overflow: scroll;

}
.entity_title_container{
  display: inline-flex;
  gap:10px;
  align-items: center;
  padding: 7px 10px;
}

.entity_title_icon{
  width: 35px;
  height: 35px;
}
.entity_title_h5{
  font-size: 19px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap:5px
}
.entity_title_p{
  font-size: 12px;
  color: #777777;
}
.entity_card_badge{
  margin: 0px 10px;
  font-size: 12px;
  background: #f5f3f3;
  padding: 5px 7px;
  border-radius: 5px;
  color: #000;
}

.entity_ico_container{
  display: grid;
  padding: 10px 14px;
  grid-template-columns: 1fr 1fr 1fr;
  gap:10px;
  box-shadow: 0px 5px 4px #efefef;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}

.entity_ico_action{
  display: flex;
  gap:10px;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  min-height: 30px;
  color: #002776;
  border-right: 1px solid rgb(175, 175, 175);
  cursor: pointer;
}
.entity_ico_action svg path{
  stroke: #002776;
}
.entity_ico_action:last-child{
  border-right: 0px;
}
.entity_content_container{
  padding: 10px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.entity_content_wrap{
  display: grid;
  grid-auto-flow: column;
  border-bottom: 1px solid #dddddd;
  /* min-height: 70px; */
  grid-template-columns: 200px 1fr;
  padding-bottom: 5px;
}
.entity_content_key{
  display: flex;
  align-items: flex-start;
  font-weight: 600;
}
.entity_content_value{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* max-width: 270px; */
  /* gap:10px;  */
  line-height: 1.7;
}
.entity_content_value span svg{
  margin: 0 auto;
}

.ud_users_heading{
  display: flex;
  justify-content: space-between;
  padding:10px;
}
.ud_users_heading h5{
  font-size: 18px;
  font-weight: 400;
}
.ud_user_title,.ud_user_subtitle{
  display: grid;
  grid-template-columns: 30px 1fr;
  gap:10px;
}

.ud_users_add_ico{
  padding:5px;
  background: #002776;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.ud_users_add_ico svg path{
  stroke: #fff;
}
.ud_users_wrap{
  display: grid;
  grid-template-columns: 70px 1fr 50px;
  place-content: center;
  padding: 10px;
  gap:10px;
  box-shadow: 0px 2px 2px rgb(0 39 118 / 11%)
}
.ud_users_discription{
  display: block;
  line-height: 1.5;
}
.ud_users_discription h5{
  margin-bottom: 7px;
  font-size: 14px;
}
.ud_users_description_ico{
  display: flex;
  align-self: center;
  justify-self: center;
}
.ud_users_discription span{
  /* display: flex; */
  /* align-items: center; */
  /* margin-bottom: 5px; */
  font-size: 12px;
  color: #B0B0B0;
}
.ud_user_hyperlink{
  color: #B0B0B0;
}
.ud_indicators{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  gap:3px;
  align-items: center;
}
.brk_word{
  word-break: break-all;
}

.ud_users_indication{
  display: flex;
  gap:10px;
  flex-wrap: wrap;
  margin: 15px 0px;
}
.ud_users_indication p{
  font-size: 12px;
  font-weight: 500;
  display: flex;
  gap:2px;
  align-items: center;
}
.ud_user_action_wrap{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

@media only screen and (min-width: 1332px) {
  .ud_contents {
    grid-template-columns: 2fr 1fr;
  }
}

@media only screen and (min-width: 1200px) {
  
  
  .entity_ico_container{
    grid-auto-flow: column;
    grid-template-columns: auto;
  }
}