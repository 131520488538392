.ad_edit_wrapper{
    display: grid;
    grid-template-columns: 1fr; 
    gap:10px;
    box-shadow: 0px 5px 10px #ededed;
    padding:20px;
    border-radius: 10px;
}
.ad_input_wrap{
    margin-bottom: 1rem;
}
.ad_input_header{
    font-size: 15px;
    color: #002776;
}
.ad_input_field{
    margin-top: 0px;
    border-radius: 5px;
    border: 1px solid #002776;
    font-family: "Poppins-Regular";
}
.ad_short_label{
    font-size: 12px;
}

.ad_button_container{
    display: flex;
    gap:20px;
    margin-top: 2rem;
}
.ad_button_container button{
    width: 100px;
    height: 40px;
    font-family: "Poppins-Regular";
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.ad_button_container .ad_primary_btn{
    border: 1px solid #002776;
    background: #002776;
    color: #fff;
}

.ad_button_container .ad_cancel_btn{
    border: 1px solid #002776;
    background: #fff;
}

@media only screen and (min-width: 992px) {
    .ad_edit_wrapper{
        grid-template-columns: 1fr 1fr;
        gap:40px
    }

}