.rfq_accordion_header{
  padding-left: 270px;
  padding-right: 290px;
}
.toggle-on{
  /* transform: rotate(90deg); */
  animation: rotate90 1s forwards;
}

.table-new{
  width: 100%;
  border: 1px solid gray;
  border-spacing: 0px;
}
.table-new thead{
  background: #007381;
  color: #fff;
}

.table-new th{
  text-align: center;
}
.td-hog{
  text-align: left;
}
.td-kg {
 text-align: right;
}
.volume-input{
  margin: 0px;
  text-align: right;
  width: 100px;
  font-weight: 500;
  font-size: 16px;
}

.volume-input-disabled{
  border: 0px;
  text-align: center;
  margin: 0px;
  width: 100px;
  font-weight: 500;
  font-size: 16px;
  background-color: #dcdcdc20;
}
.no-data{
  text-align: center;
  margin-top: 50px;
  color: '#404855';
  font-family: 'Poppins-Light';
}

@keyframes rotate90 {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(90deg);
  }
}