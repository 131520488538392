.dropdown_container{
    /* appearance: none; */
  display: block;
  margin: 5px 0px;
    border: 0;
  outline: 0;
  font: inherit;
  /* padding: 0 4em 0 1em; */
  color: #000;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
    border-radius: 0.25em;
    box-shadow: 0 0 0.4em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.dropdown_container option{
    color: #000;
    background-color: #fff;
}